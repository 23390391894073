<template>
  <article>
    <div v-if="entityDataFetching" class="current-item-wrapper">
      <div class="container blank text-center">
        <AppLoader />
      </div>
    </div>
    <div v-else-if="!entityDataFetching && !event" class="current-item-wrapper">
      <div class="container">
        <MsgEntityNotFound name="Event" />
      </div>
    </div>

    <template v-else-if="event">
      <div class="current-item-wrapper">
        <EntityPageHeader
          :image="mediaUrl"
          :image-details="event.mainMediaDetails"
          :title="event.title"
          title-before="Cultivist"
          :title-after="destinationTitle"
        />
        <div class="container">
          <div class="data-details__actions">
            <LikeToAttendAction :event="event" @show-museum-modal="isMuseumModal = true" />
            <AddToFavoriteAction :event="event" :is-favorite="isFavoriteState" @click="handleFavoriteAction" />
            <FindOnMapAction @click="showFindOnMap = true" />
            <AddToGuideAction :event="event" />
            <AddReviewAction @click="setDestinationReview(event.destination.title)" />
          </div>
          <DataItemInfo :item="event" />
        </div>
      </div>

      <EntityDetailsMap :event="event" @click-map-area="showFindOnMap = true" />
      <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="event.title" />
    </template>

    <FindOnMap
      v-if="showFindOnMap"
      :item="event"
      :latitude="entityLatitude"
      :longitude="entityLongitude"
      :zoom="13"
      @close="showFindOnMap = false"
    />
    <UpgradeFromEnthusiastMsgModal v-model="isMuseumModal" />
  </article>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import eventQuery from '@/graphql/event/Event.single.query.gql';
import addToFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import EntityPageHeader from '@/components/EntityPageHeader.vue';
import DataItemInfo from '@/components/partials/DataItemInfo.vue';
import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction';
import LikeToAttendAction from '@/components/partials/actions/LikeToAttendAction';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import FindOnMapAction from '@/components/partials/actions/FindOnMapAction.vue';
import AddToGuideAction from '@/components/partials/actions/AddToGuideAction.vue';
import AddReviewAction from '@/components/partials/actions/AddReviewAction.vue';
import EntityDetailsMap from '@/components/EntityDetailsMap.vue';

export default {
  name: 'EventSinglePage',
  components: {
    EntityDetailsMap,
    EntityPageHeader,
    DataItemInfo,
    AddReviewAction,
    AddToGuideAction,
    FindOnMapAction,
    MsgEntityNotFound,
    AddToFavoriteAction,
    LikeToAttendAction,
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection'),
    FindOnMap: () => import('@/components/partials/FindOnMap'),
    UpgradeFromEnthusiastMsgModal: () => import('@/components/modals/UpgradeFromEnthusiastMsgModal'),
  },
  metaInfo() {
    return {
      title: this.event?.title || 'Event',
    };
  },
  data() {
    return {
      event: null,
      entityDataFetching: true,
      isFavoriteState: false,
      isMuseumModal: false,
      relatedRecords: [],
      showFindOnMap: false,
    };
  },
  computed: {
    destinationTitle() {
      if (!this.event.destination || !this.event.destination.title) {
        return '';
      }

      let title = this.event.destination.title;
      if (this.event.destination.parent) {
        title += `, ${this.event.destination.parent.title}`;
      }

      return title;
    },
    entityLatitude() {
      return parseFloat(this.event.latitude);
    },
    entityLongitude() {
      return parseFloat(this.event.longitude);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.event);
    },
  },
  watch: {
    $route() {
      this.fetchEntityData();
    },
    showFindOnMap(val) {
      if (val) {
        this.$store.dispatch('disableScroll');
      } else {
        this.$store.dispatch('enableScroll');
      }
    },
  },
  created() {
    this.fetchEntityData();
  },
  methods: {
    fetchEntityData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.entityDataFetching = true;

      this.$apollo
        .query({
          query: eventQuery,
          variables,
        })
        .then(({ data }) => {
          const event = data?.entity || null;

          if (variables.onlyId && event?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, event.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          this.entityDataFetching = false;
          this.event = event;

          if (this.event) {
            this.isFavoriteState = this.event.is_favorite_by_current_user;
            this.relatedRecords = this.event.relatedRecords || [];
          }
        })
        .catch(() => {
          this.entityDataFetching = false;
        });
    },
    setDestinationReview(destination) {
      this.$store.dispatch('destinationReview', destination);
    },
    handleFavoriteAction() {
      if (!this.isFavoriteState) {
        this.$apollo
          .mutate({
            mutation: addToFavoritesMutation,
            variables: {
              entities: [{ id: this.event.id, type: this.event.__typename }],
            },
          })
          .then(({ data }) => {
            this.isFavoriteState = data.addToUserFavorites;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: this.event.id, type: this.event.__typename }],
          },
        })
        .then(({ data }) => {
          this.isFavoriteState = !data.deleteFromUserFavorites;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-item-wrapper {
  margin-top: 100px;
}

.blank {
  padding-top: 50px;
}
</style>
